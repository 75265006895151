/* The actual timeline (the vertical ruler) */

#experience{
  background: #ececec60;
}
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #727475;
  top: 0;
  bottom: 0;
  left: 20%;
  z-index: 1;
  margin-left: -3px;
}
/*date*/

 .date{
  margin-left: 5%;
} 

/* Container around content */
.expcontainer {
  padding: 10px 50px;
  position: relative;
  background-color: inherit;
  width: 60%;
}


/* The circles on the timeline */
.expcontainer::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #90CDF4;
  border: 4px solid #628fac;
  top: 5px;
  border-radius: 50%;
  z-index: 2;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 20.2%;
}


/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 12px;
  width: 0;
  z-index: 2;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 10px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -15px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
 }

 .name-style{
  font-weight: 600;
 }

 .name-style > span{
  color: rgb(117, 112, 112);
 }

 @media screen and (max-width: 768px) {
  .date{
    margin-left: 2%;
  } 

  .expcontainer {
        width: 80%;
  }

  
 }

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
/* Place the timelime to the left */
.date{
  margin-left: 12%;
}  
.timeline::after {
    left: 35.8px;
  }

/* Full-width containers */
  .expcontainer {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

/* Make sure that all arrows are pointing leftwards */
  .expcontainer::before {
    left: 58px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

/* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 5%;

  }

/* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

@media screen and (max-width: 415px) {
  .timeline::after {
    left: 30px;
  }

  /* Make sure all circles are at the same spot */
  .left::after, .right::after {
    left: 4.8%;

  }

  /* Make sure that all arrows are pointing leftwards */
  .expcontainer::before {
    left: 57px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

}