@media screen and (min-width: 768px) {

    #contact-container{

        width: 50vw;
    }

}

.error {
    padding: 1em;
    font-size: 1rem;
    color: rgb(129, 18, 18);
    font-weight: bold;
    background-color: white;
    padding: 1em;
    border-radius: 0.5rem;
    box-shadow: 0 0 6px 9px rgba(0, 0, 0, 0.15);
  }